export const getLocaleDateString = (date: Date, locale: string) => {
  return date.toLocaleDateString(locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};
export const getMonthDay = (date: Date) => {
  return date.getDate();
};
export const getShortMonthName = (date: Date, locale: string) => {
  return `${date.toLocaleString(locale, { month: 'long' }).substring(0, 3).toUpperCase()}.`;
};
