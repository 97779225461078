import React from 'react';
import { useTranslation } from 'react-i18next';
import DiscoverLink from '../buttons/DiscoverLink';
import ModalContactForm from '../contact/ModalContactForm';
import * as Colors from '../Colors';
import HeadsetSvgIcon from '../../images/icons/headset.inline.svg';
import MailSvgIcon from '../../images/icons/mail.inline.svg';

type WeTalkProps = {
  bookingUrl: string;
};

const WeTalk: React.VoidFunctionComponent<WeTalkProps> = ({ bookingUrl }) => {
  const { t } = useTranslation('academy');

  return (
    <section id="we-talk">
      <div className={`${Colors.BLACK_ON_WHITE}`}>
        <div className="container flex h-full max-w-224 flex-col justify-center px-4 pb-8 sm:px-8">
          <h2 className="text-left text-4xl font-semibold">{t('we-talk.title')}</h2>
          <p className="font-medium">{t('we-talk.description')}</p>
          <div className="relative flex flex-wrap">
            <DiscoverLink
              text={t('we-talk.button-call')}
              href={bookingUrl}
              pseudoClass={`mt-4 mr-4 ${Colors.WHITE_ON_BLUE} ${Colors.WHITE_ON_BLUE_PSEUDO_CLASS}`}
              SvgIcon={HeadsetSvgIcon}
            />
            <ModalContactForm
              pageTitle={t('head-title')}
              buttonName={t('we-talk.button-contact')}
              buttonClassName="font-semibold"
              buttonPseudoClassName={`mt-4 mr-4 ${Colors.BLUE_ON_WHITE} ${Colors.BLUE_ON_WHITE_PSEUDO_CLASS}`}
              popinClassName="origin-top-right"
              modalTheme={{
                class: Colors.WHITE_ON_BLUE,
                pseudoClass: Colors.WHITE_ON_BLUE_PSEUDO_CLASS,
              }}
              SvgIcon={MailSvgIcon}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default WeTalk;
