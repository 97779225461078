import React from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { getImage } from 'gatsby-plugin-image';
import { Post } from '../../utils/graphql';
import { NewsCard, InformationType } from '../Card';
import getThemeAndTags from './getThemeAndTags';
import PostDate from './post-date';

export const Informations = (t: TFunction, post: Post, lang: string): InformationType[] => {
  return [
    {
      title: t('publication'),
      description: <PostDate date={post.date} locale={lang} />,
    },
    {
      title: t('duration'),
      description: t('timeToRead', { count: post.duration ?? post.timeToRead }),
    },
    { title: t('language'), description: t(post.locale) },
  ];
};

type PostItemProps = {
  post: Post;
  siteUrl: string;
  lang: string;
  subLevel?: boolean;
};

const PostItem: React.VoidFunctionComponent<PostItemProps> = ({
  post,
  siteUrl,
  lang,
  subLevel,
}) => {
  const { t } = useTranslation('news');

  const themeAndTags = getThemeAndTags(t, post.theme, post.tags);

  return (
    <article itemScope itemType="https://schema.org/BlogPosting">
      <link itemProp="publisher" href={`${siteUrl}#organization`} />
      <NewsCard
        linkTo={post.slug}
        externalUrl={post.externalUrl}
        title={post.title}
        language={post.locale}
        withMicroData
        description={post.abstract}
        tags={themeAndTags}
        informations={Informations(t, post, lang)}
        image={post.socialImage && getImage(post.socialImage)}
        subLevel={subLevel}
      />
    </article>
  );
};

export default PostItem;
