import React from 'react';
import { useTranslation } from 'react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import * as Colors from '../Colors';

const Certification: React.VoidFunctionComponent = () => {
  const { t } = useTranslation('academy');

  return (
    <section id="certified-trainings">
      <div className={`${Colors.BLACK_ON_YELLOW}`}>
        <div className="container flex h-full max-w-224 flex-col justify-center px-4 pb-8 sm:px-8">
          <h2 className="text-left text-4xl font-semibold">{t('certification.title')}</h2>
          <div className="flex flex-wrap">
            <div className="whitespace-pre-line pb-4 font-medium md:w-1/2">
              {t('certification.description')}
            </div>
            <a
              href="/academy/Certificat-QUALIOPI-REACTEEV.pdf"
              target="_blank"
              rel="noopener noreferrer"
              title={t('certification.download')}
              className="block bg-white md:w-1/2"
            >
              <div>
                <StaticImage
                  src="../../images/academy/logo-qualiopi.png"
                  className="h-auto w-full max-w-88"
                  title={t('certification.qualiopi')}
                  alt={t('certification.qualiopi')}
                  placeholder="blurred"
                />
                <div className="px-6 pb-4 xs:px-8 sm:pl-9">
                  <div>{t('certification.scope-title')}</div>
                  <div className="mt-1 text-lg font-bold">{t('certification.scope-content')}</div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Certification;
