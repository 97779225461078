import { useLocalization } from 'gatsby-theme-i18n';

export function useLocalizedItem<T extends { locale: string; lang?: string; slug: string }>(
  allItems: T[],
): T[] {
  const { locale, config } = useLocalization();
  const items: T[] = [];
  allItems.forEach((item) => {
    const lang = config.find((loc) => loc.code === item.locale);
    // Enrich with lang field if it is a managed locale but not the expected one
    if (lang) {
      if (item.locale !== locale) {
        // eslint-disable-next-line no-param-reassign
        item.lang = item.locale;
      }
    } else {
      // Exclude unsupported localed
      return;
    }
    // We list posts that are either:
    // - in expected locale
    // - in another locale but does not exist in expected locale
    if (
      item.locale === locale ||
      !allItems.find((p) => p.slug === item.slug && p.locale === locale)
    ) {
      items.push(item);
    }
  });
  return items;
}
