// biome-ignore lint/style/useImportType: <explanation>
import React from 'react';
import ArrowSvgIcon from '../../images/icons/arrow.inline.svg';

type TallyButtonProps = {
  tallyId: string;
  text: string;
  colorClass: string;
  noIcon?: boolean;
};

const TallyButton: React.VoidFunctionComponent<TallyButtonProps> = ({
  tallyId,
  text,
  colorClass,
  noIcon = false,
}) => {
  return (
    <button
      type="button"
      className={`inline-flex w-fit items-center rounded-1 px-4 py-3 text-base ${colorClass}`}
      data-tally-open={tallyId}
      data-tally-layout="modal"
      data-tally-width="375"
      data-tally-align-left="1"
    >
      <span>{text}</span>
      {!noIcon && <ArrowSvgIcon className="ml-4 inline-block rotate-90" />}
    </button>
  );
};

export default TallyButton;
