import { LocalizedLink } from 'gatsby-theme-i18n';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageContextProps, Post } from '../../utils/graphql';
import * as Colors from '../Colors';
import PostItem from '../news/post-item';

type LinkedArticlesProps = {
  posts: Post[];
  siteUrl: string;
  pageContext: PageContextProps;
};

const LinkedArticles: React.VoidFunctionComponent<LinkedArticlesProps> = ({
  posts,
  siteUrl,
  pageContext,
}) => {
  const { t } = useTranslation('academy');
  return (
    <section id="articles">
      <div className={`${Colors.BLACK_ON_WHITE}`}>
        <div className="container flex h-full max-w-224 flex-col justify-center px-4 py-8 sm:px-8">
          <h2 className="text-left text-3xl font-semibold leading-snug sm:text-4xl">
            {t('articles.title')}
          </h2>
          <p>{t('articles.description')}</p>
          <div className="mt-4 flex flex-wrap justify-center lg:justify-start">
            {posts.map((post) => {
              return (
                <PostItem
                  key={`${post.locale}/${post.slug}`}
                  siteUrl={siteUrl}
                  post={post}
                  lang={pageContext.locale}
                  subLevel={true}
                />
              );
            })}
          </div>
          <div className="mt-4 text-center">
            <LocalizedLink
              to="/news"
              className={`inline-block w-fit whitespace-nowrap rounded-1 border-1 border-current px-4 py-3 font-semibold ${Colors.BLACK_ON_WHITE_PSEUDO_CLASS}`}
            >
              {t('articles.see-more-button')}
            </LocalizedLink>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LinkedArticles;
