// biome-ignore lint/style/useImportType: <explanation>
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import { LocalizedLink } from 'gatsby-theme-i18n';
import { getLocaleDateString, getMonthDay, getShortMonthName } from '../../utils/formatDate';
import type { GraphqlAcademySession } from '../../utils/graphqlNotion';
import * as Colors from '../Colors';

type CalendarDateProps = {
  date?: Date;
  locale: string;
  classElement: string;
  classMonthName: string;
  classMonthDay: string;
};
const CalendarDate: React.VoidFunctionComponent<CalendarDateProps> = ({
  date,
  locale,
  classElement,
  classMonthName,
  classMonthDay,
}) => {
  if (!date) return null;
  return (
    <span
      className={`inline-block h-[64px] border border-reacteev-blue text-center align-top ${classElement}`}
    >
      <div className={`bg-reacteev-blue font-semibold text-white ${classMonthName}`}>
        {getShortMonthName(date, locale)}
      </div>
      <div className={`font-medium ${classMonthDay}`}>{getMonthDay(date)}</div>
    </span>
  );
};
type CalendarDatesProps = {
  startDate: Date;
  endDate?: Date;
  locale: string;
};
const CalendarDates: React.VoidFunctionComponent<CalendarDatesProps> = ({
  startDate,
  endDate,
  locale,
}) => {
  return (
    <>
      <CalendarDate
        date={startDate}
        locale={locale}
        classElement={`${
          endDate
            ? 'w-[44px] min-w-[44px] rounded-bl-lg rounded-tl-lg'
            : 'w-[64px] min-w-[64px] rounded-lg'
        }`}
        classMonthName={`${endDate ? 'rounded-tl-lg text-xs' : 'rounded-t-lg text-sm'}`}
        classMonthDay={`${endDate ? 'text-[24px] leading-relaxed' : 'text-[32px] leading-tight'}`}
      />
      <CalendarDate
        date={endDate}
        locale={locale}
        classElement="w-[44px] min-w-[44px] rounded-br-lg rounded-tr-lg"
        classMonthName="rounded-tr-lg text-xs"
        classMonthDay="text-[24px] leading-relaxed"
      />
    </>
  );
};

type LocaleDateProps = {
  itemProp: string;
  date?: Date;
  text: string;
  locale: string;
};
const LocaleDate: React.VoidFunctionComponent<LocaleDateProps> = ({
  itemProp,
  date,
  text,
  locale,
}) => {
  if (!date) return null;
  return (
    <div itemProp={itemProp} content={date.toISOString()} className="font-medium">
      <span className="w-20 font-light">{text}</span> {getLocaleDateString(date, locale)}
    </div>
  );
};

type EventProps = {
  event: GraphqlAcademySession;
  locale: string;
};

const Event: React.VoidFunctionComponent<EventProps> = ({ event, locale }) => {
  const { t } = useTranslation('academy');
  const startDate = new Date(event.properties.start_date);
  let endDate: Date | undefined;
  if (event.properties.end_date) {
    endDate = new Date(event.properties.end_date);
  }
  const defaultLanguage = 'fr';
  const defaultLocation = 'Paris, France';

  return (
    <article
      itemScope
      itemType="https://schema.org/Event"
      className="mb-5 mr-5 w-[360px] rounded border p-4 shadow-2xl"
    >
      <meta itemProp="isAccessibleForFree" itemType="http://schema.org/False" />
      <div className="flex">
        <CalendarDates startDate={startDate} locale={locale} endDate={endDate} />
        <span className="ml-4 inline-block">
          <h3 itemProp="name" className="my-0 text-left text-lg font-semibold leading-snug">
            {event.properties.name}
          </h3>
          <LocaleDate
            itemProp="startDate"
            date={startDate}
            text={t(`${endDate ? 'next-events.card.from' : 'next-events.card.the'}`)}
            locale={locale}
          />
          <LocaleDate
            itemProp="endDate"
            date={endDate}
            text={t('next-events.card.to')}
            locale={locale}
          />
        </span>
      </div>
      <div itemProp="about" className="mt-5 text-sm font-medium text-gray-500">
        {event.properties.abstract}
      </div>
      <div itemProp="performer" className="mt-5 inline-flex w-full">
        <span className="min-w-24 font-light">{t('next-events.card.with')}</span>
        <span className="font-medium">{event.properties.teachers}</span>
      </div>
      <div itemProp="inLanguage" className="inline-flex w-full">
        <span className="min-w-24 font-light">{t('next-events.card.language')}</span>
        <span className="font-medium">
          {t(`next-events.card.languages.${event.properties.language || defaultLanguage}`)}
        </span>
      </div>
      <div
        itemProp="location"
        itemScope
        itemType="https://schema.org/Place"
        className="inline-flex w-full"
      >
        <span className="min-w-24 font-light">{t('next-events.card.location')}</span>
        <meta itemProp="name" content={event.properties.place} />
        <span itemProp="address" itemType="https://schema.org/Text" className="font-medium">
          {event.properties.place || defaultLocation}
        </span>
      </div>
      <div itemProp="additionalType" content="url" className="mt-5 text-reacteev-blue">
        {event.properties.status === 'Draft' ? (
          <span className="mb-2 mr-2 inline-block bg-reacteev-blue px-3 py-1 font-medium text-white">
            Draft
          </span>
        ) : null}
        <LocalizedLink
          to={`${event.slug}?date=${event.properties.start_date}`}
          language={event.locale}
        >
          {t('next-events.card.find-out-more')}
        </LocalizedLink>
      </div>
    </article>
  );
};

type NextEventsProps = {
  locale: string;
  events: GraphqlAcademySession[];
};

const NextEvents: React.VoidFunctionComponent<NextEventsProps> = ({ locale, events }) => {
  const { t } = useTranslation('academy');
  if (events.length === 0) return <></>;
  events = events.filter((event) => {
    return new Date(event.properties.start_date) >= new Date();
  });
  const startDate = new Date(events[0].properties.start_date);
  let endDate: Date | undefined;
  if (events[0].properties.end_date) {
    endDate = new Date(events[0].properties.end_date);
  }
  const buttonName = t('next-events.card.find-out-more');

  return (
    <section id="next-events">
      <div className={`${Colors.BLACK_ON_WHITE}`}>
        <div className="container flex h-full max-w-224 flex-col justify-center px-4 pb-8 sm:px-8">
          <h2 className="text-left text-4xl font-semibold">{t('next-events.title')}</h2>
          <div className="mt-4 hidden lg:flex">
            <div>
              <div className="flex">
                <CalendarDates startDate={startDate} locale={locale} endDate={endDate} />
                <span className="my-0 ml-4 inline-block text-3xl font-semibold leading-none">
                  {events[0].properties.name}
                </span>
              </div>
              <p className="mt-4 font-medium">{events[0].properties.abstract}</p>
              <LocalizedLink
                to={`${events[0].slug}?date=${events[0].properties.start_date}`}
                language={events[0].locale}
                className={`mt-4 block max-w-max whitespace-nowrap border border-solid border-current px-4 py-3 font-semibold no-underline ${Colors.BLACK_ON_WHITE_PSEUDO_CLASS}`}
              >
                {buttonName}
              </LocalizedLink>
            </div>
            <StaticImage
              src="../../images/academy/cyd.png"
              alt={events[0].properties.name}
              className="min-w-96"
            />
          </div>
          <div className="mt-8 flex flex-wrap">
            {events.map((event) => (
              <Event
                key={`${event.slug}-${event.properties.start_date}`}
                event={event}
                locale={locale}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default NextEvents;
