import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Colors from '../Colors';
import SvgCaretDown from '../../images/icons/caretDown.inline.svg';

type QuestionProps = {
  questionNumber: number;
  question: string;
  answer: string;
};

const Question: React.VoidFunctionComponent<QuestionProps> = ({
  questionNumber,
  question,
  answer,
}) => {
  const { t } = useTranslation('academy');
  const [open, setOpen] = React.useState<boolean>(false);
  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setOpen(!open);
  };

  return (
    <div
      className="container flex h-full max-w-224 flex-col justify-center pb-4"
      itemScope
      itemProp="mainEntity"
      itemType="https://schema.org/Question"
    >
      <button
        className="flex w-full items-center justify-between"
        type="button"
        aria-expanded={open}
        aria-controls={`question-${questionNumber}`}
        onClick={onClick}
        title={
          open
            ? t('questions.expandSection.close', { name: question.toString() })
            : t('questions.expandSection.open', { name: question.toString() })
        }
      >
        <h3 className="text-left text-xl font-semibold" itemProp="name">
          {question}
        </h3>
        <SvgCaretDown
          className={`w-[36px] transform p-[5px] transition-all ${open ? 'rotate-180' : ''}`}
        />
      </button>
      <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
        <p
          id={`question-${questionNumber}`}
          className={`whitespace-pre-line text-sm font-medium ${open ? '' : 'line-clamp-2'}`}
          itemProp="text"
        >
          {answer}
        </p>
      </div>
    </div>
  );
};

const Questions: React.VoidFunctionComponent = () => {
  const { t } = useTranslation('academy');
  const questionsData = t('questions.data', { returnObjects: true });
  const questionsArray = Array.from(Array(questionsData.length).keys());

  return (
    <section id="questions">
      <div className={`${Colors.BLACK_ON_YELLOW}`}>
        <div
          className="container flex h-full max-w-224 flex-col justify-center px-4 pb-8 pt-4 sm:px-8"
          itemScope
          itemType="https://schema.org/FAQPage"
        >
          <h2 className="text-left text-4xl font-semibold">{t('questions.title')}</h2>
          {questionsArray.map((questionNumber) => (
            <Question
              key={questionNumber}
              questionNumber={questionNumber}
              question={t(`questions.data.${questionNumber}.question`)}
              answer={t(`questions.data.${questionNumber}.answer`)}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Questions;
