import * as React from 'react';
import type { XOR } from 'ts-essentials';
import { useTranslation } from 'react-i18next';
import ArrowSvgIcon from '../../images/icons/arrow.inline.svg';

type HTMLLinkProps = {
  href: string;
  target: string;
  rel: string;
  to?: never;
};
type LocalizedLinkProps = {
  to: string;
  href?: never;
  target?: never;
  rel?: never;
};
type LocalPropsProps = {
  title?: string;
  className?: string;
};
type DiscoverDesignProps = {
  component: string | React.FunctionComponent;
  text?: string;
  title?: string;
  pseudoClass: string;
  svgClass?: string;
  textClass?: string;
  props: XOR<HTMLLinkProps, LocalizedLinkProps>;
  SvgIcon?: React.VoidFunctionComponent<React.SVGAttributes<SVGElement>>;
};

const DiscoverDesign: React.VoidFunctionComponent<DiscoverDesignProps> = ({
  component,
  text,
  title,
  pseudoClass,
  svgClass = '',
  textClass = '',
  props,
  SvgIcon,
}) => {
  const { t } = useTranslation('components');
  const localProps = props as (HTMLLinkProps | LocalizedLinkProps) & LocalPropsProps;
  localProps.title = title ?? text;
  localProps.className = `inline-flex items-center w-fit rounded-1 border-1 border-current px-4 py-3 font-semibold ${pseudoClass}`;
  const children = (
    <>
      <span className={`pr-6 ${textClass}`}>{text ?? t('button.discover')}</span>
      {SvgIcon ? (
        <SvgIcon className={`inline-block ${svgClass}`} />
      ) : (
        <ArrowSvgIcon className={`inline-block ${svgClass}`} />
      )}
    </>
  );

  return React.createElement(
    component,
    localProps as React.Attributes | null | undefined,
    children,
  );
};

export default DiscoverDesign;
