// biome-ignore lint/style/useImportType: <explanation>
import React from 'react';
import { useTranslation } from 'react-i18next';
import HeadsetSvgIcon from '../../images/icons/headset.inline.svg';
import * as Colors from '../Colors';
import DiscoverLink from '../buttons/DiscoverLink';
import TallyButton from './TallyButton';

type OfferCardProps = {
  offer: string;
  tallyId: string;
  mainClass: string;
};

const OfferCard: React.VoidFunctionComponent<OfferCardProps> = ({ offer, tallyId, mainClass }) => {
  const { t } = useTranslation('academy');
  return (
    <div
      className={`flex w-full flex-col justify-between p-6 ${Colors.BLACK_ON_YELLOW} ${mainClass}`}
    >
      <div>
        <h3 className="mb-0 text-left text-3xl font-semibold leading-none text-reacteev-blue">
          {t(`our-offers.${offer}.title`)}
        </h3>
        <div className="text-2xl font-medium">{t(`our-offers.${offer}.subtitle`)}</div>
        <ul className="ml-6 mt-4 list-disc">
          <li className="mt-2">{t(`our-offers.${offer}.item1`)}</li>
          <li className="mt-2">{t(`our-offers.${offer}.item2`)}</li>
          <li className="mt-2">{t(`our-offers.${offer}.item3`)}</li>
        </ul>
        <p className="mt-4 text-reacteev-blue">{t(`our-offers.${offer}.choose-if-you-want`)}</p>
        <ul className="ml-6 list-disc text-reacteev-blue">
          <li className="mt-2">{t(`our-offers.${offer}.impact1`)}</li>
          <li className="mt-2">{t(`our-offers.${offer}.impact2`)}</li>
          <li className="mt-2">{t(`our-offers.${offer}.impact3`)}</li>
        </ul>
      </div>
      <TallyButton
        tallyId={tallyId}
        text={t(`our-offers.${offer}.discover-cta`)}
        colorClass={`mx-auto mt-6 font-semibold ${Colors.BLUE_ON_WHITE} ${Colors.BLUE_ON_WHITE_PSEUDO_CLASS}`}
      />
    </div>
  );
};

type OurOffersProps = {
  bookingUrl: string;
};

const OurOffers: React.VoidFunctionComponent<OurOffersProps> = ({ bookingUrl }) => {
  const { t } = useTranslation('academy');
  return (
    <section id="our-offers" className={Colors.WHITE_ON_BLACK}>
      <div className="container flex h-full max-w-224 flex-col justify-center px-4 pb-8 pt-16 sm:px-8">
        <h2 className="text-left text-4xl font-semibold">{t('our-offers.title')}</h2>
        <p>{t('our-offers.description')}</p>
        <div className="my-4 flex flex-wrap lg:flex-nowrap">
          <OfferCard
            offer="trainings"
            tallyId={t('introduction.training-catalog-tally-id')}
            mainClass="mb-4 lg:mb-0 lg:mr-2"
          />
          <OfferCard
            offer="workshops"
            tallyId={t('introduction.workshop-catalog-tally-id')}
            mainClass="lg:ml-2"
          />
        </div>
        <div
          className={`flex flex-wrap items-center px-5 py-5 md:flex-nowrap md:px-14 ${Colors.WHITE_ON_BLUE}`}
        >
          <p className="my-2 text-2xl font-medium md:mr-5">{t('our-offers.cta')}</p>
          <DiscoverLink
            text={t('we-talk.button-call')}
            href={bookingUrl}
            pseudoClass={`my-2 ${Colors.WHITE_ON_BLUE} ${Colors.WHITE_ON_BLUE_PSEUDO_CLASS}`}
            SvgIcon={HeadsetSvgIcon}
            textClass="whitespace-nowrap"
          />
        </div>
      </div>
    </section>
  );
};

export default OurOffers;
