import { TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import * as Colors from '../../components/Colors';
import ChalkboardTeacherIcon from '../../images/academy/chalkboardTeacher.inline.svg';
import HandFistIcon from '../../images/academy/handFist.inline.svg';
import SealCheckIcon from '../../images/academy/sealCheck.inline.svg';
import SlidersIcon from '../../images/academy/sliders.inline.svg';

type ExpertiseProps = {
  t: TFunction;
  expertise: string;
  Icon: React.VoidFunctionComponent<React.SVGAttributes<SVGElement>>;
};

const Expertise: React.VoidFunctionComponent<ExpertiseProps> = ({ t, expertise, Icon }) => {
  return (
    <div className="mx-4 mb-8 max-w-64 text-center">
      <div>
        <Icon className="inline h-14" />
      </div>
      <h3 className="text-base font-bold">
        {t(`introduction.expertises.expertises.${expertise}.title`)}
      </h3>
      <p className="text-sm font-medium">
        {t(`introduction.expertises.expertises.${expertise}.description`)}
      </p>
    </div>
  );
};

const Introduction: React.VoidFunctionComponent = () => {
  const { t } = useTranslation('academy');

  return (
    <div className="flex w-full">
      <header className={`w-full snap-start px-4 pb-8 pt-16 sm:px-8 ${Colors.WHITE_ON_BLACK}`}>
        <div className="flex flex-wrap justify-center xl:flex-nowrap">
          <div className="m-4 md:max-w-1/2">
            <h1 className="whitespace-pre-line text-left text-5xl font-semibold md:text-7xl">
              {t('introduction.title')}
            </h1>
            <p className="whitespace-pre-line font-medium">{t('introduction.description1')}</p>
            <p className="whitespace-pre-line font-medium">{t('introduction.description2')}</p>
            <p className="whitespace-pre-line font-medium">{t('introduction.description3')}</p>
            <a
              href="#we-talk"
              title={t('we-talk.title')}
              className={`mt-4 block max-w-max whitespace-nowrap border border-solid border-current px-4 py-3 no-underline ${Colors.WHITE_ON_BLACK_PSEUDO_CLASS}`}
            >
              {t('we-talk.title')}
            </a>
          </div>
          <div className="m-4 md:max-w-1/2">
            <StaticImage
              className="md:w-[450px]"
              src="../../images/academy/cover.png"
              alt={'altTitle'}
              title={'altTitle'}
              width={578}
              placeholder="blurred"
            />
          </div>
        </div>
        <h2 className="text-3xl font-semibold md:text-4xl">{t('introduction.expertises.title')}</h2>
        <div className="flex flex-wrap justify-center">
          <Expertise t={t} expertise={'expertise1'} Icon={SealCheckIcon} />
          <Expertise t={t} expertise={'expertise2'} Icon={ChalkboardTeacherIcon} />
          <Expertise t={t} expertise={'expertise3'} Icon={HandFistIcon} />
          <Expertise t={t} expertise={'expertise4'} Icon={SlidersIcon} />
        </div>
      </header>
    </div>
  );
};

export default Introduction;
