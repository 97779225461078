// biome-ignore lint/style/useImportType: <explanation>
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Colors from '../Colors';
import ExpandableSection from './ExpandableSection';

type Themes = Record<
  string,
  {
    title: string;
    description: string;
    trainings: string[];
    workshops: string[];
  }
>;

const OurThemes: React.VoidFunctionComponent = () => {
  const { t } = useTranslation('academy');
  const themes: Themes = t('our-themes.themes', { returnObjects: true });

  return (
    <section id="our-themes" className={Colors.WHITE_ON_BLACK}>
      <div className="container flex h-full max-w-224 flex-col justify-center px-4 pb-8 pt-16 sm:px-8">
        <div className={`p-4 ${Colors.BLACK_ON_WHITE}`}>
          <h2 className="text-left text-4xl font-semibold">{t('our-themes.title')}</h2>
          {Object.entries(themes).map(
            ([theme, { title, description, trainings, workshops }], index) => (
              <ExpandableSection key={theme} number={index} title={title}>
                <>
                  <p>{description}</p>
                  {trainings.length !== 0 && (
                    <>
                      <p className="mt-2">{t('our-themes.our-main-trainings')}</p>
                      <ul className="mt-2 list-inside list-disc pl-4 text-xs text-gray-500">
                        {trainings.map((training) => (
                          <li key={training}>{training}</li>
                        ))}
                      </ul>
                    </>
                  )}
                  {workshops.length !== 0 && (
                    <>
                      <p className="mt-2">{t('our-themes.our-main-workshops')}</p>
                      <ul className="mt-2 list-inside list-disc pl-4 text-xs text-gray-500">
                        {workshops.map((workshop) => (
                          <li key={workshop}>{workshop}</li>
                        ))}
                      </ul>
                    </>
                  )}
                </>
              </ExpandableSection>
            ),
          )}
        </div>
      </div>
    </section>
  );
};

export default OurThemes;
