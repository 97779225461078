import React from 'react';
import { useTranslation } from 'react-i18next';
import SvgCaretDown from '../../images/icons/caretDown.inline.svg';

type ExpandableSectionProps = {
  number: number;
  title: string | React.ReactElement;
  children: React.ReactNode;
};

const ExpandableSection: React.VoidFunctionComponent<ExpandableSectionProps> = ({
  number,
  title,
  children,
}) => {
  const { t } = useTranslation('academy');
  const [open, setOpen] = React.useState<boolean>(false);
  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setOpen(!open);
  };

  return (
    <div className="container flex h-full max-w-224 flex-col justify-center border-b border-black pl-4 last:border-0">
      <button
        className="flex w-full items-center justify-between"
        type="button"
        aria-expanded={open}
        aria-controls={`section-${number}`}
        onClick={onClick}
        title={
          open
            ? t('expandSection.close', { name: title.toString() })
            : t('expandSection.open', { name: title.toString() })
        }
      >
        <h3 className="text-left text-xl font-semibold">{title}</h3>
        <SvgCaretDown
          className={`w-[36px] transform p-[5px] transition-all ${open ? 'rotate-180' : ''}`}
        />
      </button>
      <div
        id={`section-${number}`}
        className={`whitespace-pre-line pb-4 pl-4 text-sm font-medium text-gray-700 ${
          open ? '' : 'hidden'
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default ExpandableSection;
